var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"row col-11 page-title-box d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria font-size-24' : 'font-size-16'},[_c('i',{class:_vm.pgIcon}),_vm._v(" "+_vm._s(_vm.pgTitle)+" "),(_vm.addNew)?_c('router-link',{staticClass:"btn btn-primary btn-rounded waves-effect waves-light mb-2 me-2",class:[
            _vm.$i18n.locale == 'ar' ? 'hacen_algeria' : '',
            _vm.theme_color == 'purple' ? 'btn-purple' : '',
          ],attrs:{"to":'/' +
            _vm.refs +
            '/create?parent_id=' +
            _vm.row.parent_id +
            '&slug=' +
            _vm.row.slug}},[_c('i',{staticClass:"mdi mdi-plus-circle"}),_vm._v(" "+_vm._s(_vm.$t("app.addNew"))+" ")]):_c('a',{staticClass:"btn btn-primary mb-2 opacity-0"},[_vm._v("hidden")])],1),_c('div',{staticClass:"page-title-right col-12",style:(_vm.$i18n.locale == 'en' ? 'padding:0' : '')},[_c('ol',{staticClass:"breadcrumb m-0"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria' : '',attrs:{"to":"/"}},[_vm._v(" "+_vm._s(_vm.$t("app.dashboard"))+" ")])],1),(_vm.refs)?_c('li',{staticClass:"breadcrumb-item text-capitalize",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria' : ''},[_c('router-link',{attrs:{"to":{ name: _vm.refs }}},[_vm._v(" "+_vm._s(_vm.pgTitle)+" ")])],1):_vm._e(),(_vm.pgCurrent)?_c('li',{staticClass:"breadcrumb-item active",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria' : ''},[_vm._v(" "+_vm._s(_vm.pgCurrent == "Add New" ? _vm.$t("app.addNew") : _vm.pgCurrent == "Edit" ? _vm.$t("app.edit") : _vm.$t("app.analytics"))+" ")]):_vm._e(),(_vm.row.slug)?_c('li',{staticClass:"breadcrumb-item active",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria' : ''},[_vm._v(" "+_vm._s(_vm.row.slug)+" ")]):_vm._e()])])]),(_vm.auth.role)?_c('div',{staticClass:"col-1",class:_vm.$i18n.locale == 'en' ? 'roleStyleEn' : 'roleStyleAr'},[_c('label',{staticClass:"badge rounded-pill badge-md badge-soft-success",class:_vm.$i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : '',staticStyle:{"padding":"10px"}},[_vm._v(" "+_vm._s(_vm.auth.role)+" ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }