<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12" style="display: flex">
      <div
        class="row col-11 page-title-box d-flex align-items-center justify-content-between"
      >
        <h4
          class="mb-0"
          :class="
            $i18n.locale == 'ar' ? 'hacen_algeria font-size-24' : 'font-size-16'
          "
        >
          <i :class="pgIcon"></i>
          {{ pgTitle }}
          <router-link
            v-if="addNew"
            :to="
              '/' +
              refs +
              '/create?parent_id=' +
              row.parent_id +
              '&slug=' +
              row.slug
            "
            class="btn btn-primary btn-rounded waves-effect waves-light mb-2 me-2"
            :class="[
              $i18n.locale == 'ar' ? 'hacen_algeria' : '',
              theme_color == 'purple' ? 'btn-purple' : '',
            ]"
          >
            <i class="mdi mdi-plus-circle"></i>
            {{ $t("app.addNew") }}
          </router-link>
          <a v-else class="btn btn-primary mb-2 opacity-0">hidden</a>
        </h4>

        <div
          class="page-title-right col-12"
          :style="$i18n.locale == 'en' ? 'padding:0' : ''"
        >
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item">
              <router-link
                to="/"
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
              >
                {{ $t("app.dashboard") }}
              </router-link>
            </li>
            <li
              v-if="refs"
              class="breadcrumb-item text-capitalize"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
            >
              <router-link :to="{ name: refs }">
                {{ pgTitle }}
              </router-link>
            </li>
            <li
              v-if="pgCurrent"
              class="breadcrumb-item active"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
            >
              {{
                pgCurrent == "Add New"
                  ? $t("app.addNew")
                  : pgCurrent == "Edit"
                  ? $t("app.edit")
                  : $t("app.analytics")
              }}
            </li>
            <li
              v-if="row.slug"
              class="breadcrumb-item active"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
            >
              {{ row.slug }}
            </li>
          </ol>
        </div>
      </div>

      <div
        v-if="auth.role"
        class="col-1"
        :class="$i18n.locale == 'en' ? 'roleStyleEn' : 'roleStyleAr'"
      >
        <label
          class="badge rounded-pill badge-md badge-soft-success"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''"
          style="padding: 10px"
        >
          {{ auth.role }}
        </label>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ["pgCurrent", "pgTitle", "pgIcon", "addNew", "refs"],
  data() {
    return {
      auth: {
        role: "",
      },
      row: {
        slug: "",
        parent_id: "",
      },
      theme_color: "",
    };
  },
  watch() {
    //
  },
  mounted() {},
  created() {
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }
    if (localStorage.getItem("theme_color")) {
      this.theme_color = localStorage.getItem("theme_color");
    }

    if (this.$route.query.parent_id) {
      this.row.parent_id = this.$route.query.parent_id;
    }

    if (this.$route.query.slug) {
      this.row.slug = this.$route.query.slug;
    }
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.roleStyleEn {
  float: right;
  margin-right: 25px;
  padding: 0;
  text-align: right;
}
.roleStyleAr {
  float: left;
  margin-right: 25px;
  padding: 0;
  text-align: left;
}
@media (max-width: 600px) {
  .roleStyleAr {
    margin-right: -10px;
  }
}
</style>